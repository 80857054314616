.header {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  height: 12vh;
  position: relative;
}

.header-top {
  display: flex;
  width: 100%;
  background-color: #fff;
  /* height: 80px; */
}
.header-top__navbar {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
}

.header-top__seperator {
  width: 100%;
  border: none;
  height: 2px;
  background-color: black;
}

.header-top__navigation {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 55px;
}