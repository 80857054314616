.navbar {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    width: 100vw;
    /* justify-content: space; */
  }
  
.navbar-item-btn { 
  cursor: pointer; 
  /* background-color: #fff;  */
  height: 25px;
  width: 100px; 
  color: #000; 
  box-shadow: none; 
  color: #fff; 
  display: 'inline-block';
  display: flex;
  align-items: center;
  justify-content: center;
} 

.navbar-item-img-btn { 
  cursor: pointer; 
  background-color: #fff;
  box-shadow: none; 
}