  .header-top__seperatorchat {
    width: 100%;
    border: none;
    height: 1px;
    /* border-top: px solid black; */
    background-color: black;
    margin-top: -7px;
  }
  
  .header-top__navigationchat {
    display: flex;
    /* justify-content: flex-end; */
    flex-direction: row;
    /* height: 40px; */
    /* padding-right: 55px; */
  }

  #popUpButton:hover{
    background-color: #0056b3;
    border-color: #0056b3;
    color: #fff
  }
  .navbarchat {
    display: flex;
    flex-direction: row;
  }
  
.navbar-item-btnchat { 
  cursor: pointer; 
  /* background-color: transparent;  */
  color: #fff; 
  height: 30px;
  display: 'inline-block';
  /* padding-right: 20px; */
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
} 